<template>
	<Step
		:title="$t('onboarding-phone-title')"
		showNext
		@goNext="save"
		@keyup.enter="save(),goNext()"
	>
		<div class="custom-row mb-3">
			<div class="col-md-8 col-lg-6 col-xl-5">
				<PhoneInput
					@savePhone="savePhone"
				/>
			</div>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import PhoneInput from '@/components/Common/PhoneInput.vue';

export default {
	name: 'PhoneNumber',
	components: {
		Step,
		PhoneInput
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			phone: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		}
	},
	methods: {
		savePhone(e) {
			this.phone = e
		},
		save() {
			this.$emit('saveProfileInfo', 'phone', this.phone);
		},
	}
}
</script>

<style lang="scss">
</style>