<template>
	<div class="flag-icon">
		<img v-baseUrl :src="getFlagImg(code)" :alt="code">
	</div>
</template>

<script>
export default {
	props: ['code'],
	name: 'Flag',
	methods: {
		getFlagImg(code) {
			try {
				return require(`@/assets/img/flags/${code}.svg`)
			} catch (ex) {}
			
			return ''
		}
	}
}
</script>

<style lang="scss">
.flag-icon {
	img {
		width: 30px;
		display: block;
	}
}
</style>