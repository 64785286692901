<template>
	<label :class="`custom-checkbox column-${column}`">
		<input
			:type="multiple ? 'checkbox' : 'radio'"
			:value="value ? item[value] : item"
			v-model="result"
			@change="onchange"
			:checked="checked"
		>
		<div class="fake-label">
			<slot></slot>
		</div>
	</label>
</template>

<script>
export default {
	name: 'StepCard',
	props: {
		item: {
			type: Object,
			required: true
		},
		multiple: {
			type: Boolean,
			default: false
		},
		column: {
			type: Number,
			default: 4
		},
		value: {
			type: String
		},
		selectedValue: {}
	},
	data() {
		return {
			result: null,
			checked: false
		}
	},
	methods: {
		onchange() {
			this.$emit('onchange', this.result);
		}
	},
	mounted() {
		if(this.multiple && this.selectedValue.includes(this.value ? this.item[this.value] : this.item)) {
			this.checked = true;
		} else if(!this.multiple && this.selectedValue == (this.value ? this.item[this.value] : this.item)) {
			this.checked = true;
		}
	}
}
</script>

<style lang="scss">
.custom-row {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.custom-checkbox,
	.custom-radio {
		padding: 1px;
		font-size: 16px;
		max-width: calc(100% - 0.5rem);
		margin: 0 0.25rem 0.5rem;
		margin-bottom: 0.5rem;

		@include media-up(s2k) {
			font-size: 20px;
		}

		@media(min-width: 480px) {
			max-width: calc(50% - 0.5rem);
		}

		&.column-10 {
			max-width: calc(50% - 0.5rem);

			@include media-up(md){
				max-width: calc(33.332% - 0.5rem);
			}

			@include media-up(xl){
				max-width: calc(10% - 0.5rem);
			}
		}

		&.column-7 {
			max-width: calc(50% - 0.5rem);

			@include media-up(md) {
				max-width: calc(25% - 0.5rem);
			}

			@include media-up(xl) {
				max-width: calc(14% - 0.5rem);
			}
		}

		&.column-4 {
			@include media-up(xl) {
				max-width: calc(25% - 0.5rem);
			}
		}

		&.column-3 {
			@include media-up(md) {
				max-width: calc(50% - 0.5rem) !important;
			}

			@include media-up(lg) {
				max-width: calc(33.332% - 0.5rem) !important;
			}
		}

		&.column-3 {
			@include media-up(xl) {
				max-width: calc(50% - 0.5rem);
			}
		}

		&:last-of-type {
			margin-right: 0;
		}

		.fake-title {
			padding: 5px 0;
			display: block;
			text-align: center;
			text-transform: capitalize;
			font-size: 18px;
			font-weight: 600;
		}
	}
}

.custom-checkbox {
	margin-right: 0.5rem;
	max-width: calc(50% - 0.5rem);
	width: 100%;
	display: flex;

	&.img-checkbox {
		.fake-title {
			@media (min-width: 576px) {
				padding: 10px 0 0;
			}
		}
	}

	&.focus-checkbox {
		font-size: 24px;
	}

	&.unfocus-checkbox {
		font-size: 16px;
	}

	input[type="radio"] {
		position: absolute;
		opacity: 0;
		z-index: -1;
		pointer-events: none;
	}

	.img-wrap {
		background-color: #fff;
		display: -ms-flex;
		display: flex;
		-ms-align-items: center;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		cursor: pointer;
		font-size: 16px;
		transition: transform .3s, border .3s;
	}

	input[type="checkbox"] {
		position: fixed;
		left: 0;
		top: 0;
		opacity: 0;
		z-index: -1;
	}

	input[type="checkbox"]:checked+.img-wrap {
		transform: scale(0.9);
		border: 2px solid #000;
	}

	.img-holder {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		padding-bottom: 56%;
		position: relative;
		overflow: hidden;
		margin-bottom: 10px;

		@include media-up(lg) {
			padding-bottom: 45%;
		}

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			min-width: 100%;
			min-height: 100%;
		}
	}

	.fake-label {
		background-color: $gray-150;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 0px;
		color: $black;
		border-radius: 4px;
		overflow: hidden;
		width: 100%;
		padding: 7px;
		transition: box-shadow .3s, background-color .3s;
		cursor: pointer;

		@include media-up(lg) {
			padding: 15px;
		}

		@include media-up(s2k) {
			padding: 25px;
		}
	}

	.icon-holder {
		font-size: 30px;
		color: #200E32;
		margin-bottom: 10px;
		text-align: center;
	}
}

.custom-checkbox.icon-holder {
	.fake-label {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
	}
}

.custom-checkbox input:checked + .fake-label,
.custom-radio input:checked + .fake-label,
.custom-checkbox.active .fake-label {
	background-color:#E1F0FF;
}

.custom-checkbox .fake-label:hover,
.custom-radio .fake-label:hover {
	background-color: #E1F0FF;
}
</style>