<template>
	<a href="#" class="close-btn" @click.prevent="close">Close</a>
</template>

<script>

export default {
	name: 'CloseBtn',
	props: {
		close: Function
	}
}
</script>

<style scoped lang="scss">
.close-btn {
	position: relative;
	font-size: 0;
	display: inline-block;
	vertical-align: middle;
	background: $gray-200;
	border-radius: 7px;
	width: 30px;
	height: 30px;

	&:after,
	&:before {
		content: '';
		position: absolute;
		width: 12px;
		height: 2px;
		background-color: $primary;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:before {
		width: 2px;
		height: 12px;
	}
}
</style>