<template>
	<div class="step-content">
		<div>
			<h4>{{ title }}</h4>
			<slot></slot>
		</div>
		<div class="text-center" v-if="showNext">
			<a
				href="#"
				class="btn btn-outline-primary"
				@click.prevent="goNext"
			>Next</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Step',
	props: {
		title: String,
		showNext: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		goNext() {
			this.$emit('goNext');
		}
	}
}
</script>

<style lang="scss">
.step-content {
	display: grid;
	grid-template-rows: 1fr 100px;

	h4 {
		text-align: center;
		font-weight: 500;
		font-size: 26px;
		color: $primary;
		margin-bottom: 30px;

		@media(min-width: 1024px) {
			font-size: 42px;
		}
	}
}
</style>