<template>
	<Step
		:title="$t('onboarding-location-area-title')"
		showNext
		@goNext="goNext"
	>
		<div class="custom-row mb-4" v-if="profile">
			<StepCard
				v-for="(loc, i) in locations"
				:key="`location-${i}`"
				:item="loc"
				:onchange="saveLocations"
				:column="7"
				multiple
				:selectedValue="selectedLocations.concat(selectedDropLocations)"
			>
				<div  class="img-holder">
					<img :src="getImg(loc)" alt="">
				</div>
				<strong class="fake-title" >{{ locationTagNameFormat(loc) }}</strong>
			</StepCard>
		</div>
		<SelectLocationBox
			:selectedValue="selectedLocations.concat(selectedDropLocations)"
			:mainLocation="mainLocation"
			@saveDropLocations="saveDropLocations"
		/>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';
import SelectLocationBox from '@/components/Onboarding/SelectLocationBox.vue';
import { popularLocations } from '@/static/staticData.js'

export default {
	name: 'LocationSelect',
	components: {
		Step,
		StepCard,
		SelectLocationBox
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			location: null,
			selectedLocations: [],
			selectedDropLocations: [],
			popularLocations: popularLocations
		}
	},
	computed: {
		locations() {
			let loc = this.popularLocations.filter(location => {
				return location.includes(this.mainLocation)
			})

			if(window.innerWidth < 480) {
				loc = loc.splice(0, 2)
			} else if(window.innerWidth < 768) {
				loc = loc.splice(0, 4)
			} else if(window.innerWidth < 1024) {
				loc = loc.splice(0, 8)
			}

			return loc.concat(this.selectedDropLocations)
		},
		profile() {
			return this.$store.getters.profile;
		},
		mainLocation() {
			return this.profile.tags
			.filter(item => item.tagtype == 'Location')
			.map(item => item.name ? item.name : item)
			.find(item => item.split(' > ').length >= 2)
		}
	},
	methods: {
		saveLocations(e) {
			let loc = e.target.value

			if(this.selectedLocations.includes(loc)) {
				this.selectedLocations = this.selectedLocations.filter(item => item != loc)
				this.$emit('saveProfileLocationTags', null, loc);
			} else {
				this.selectedLocations.push(loc)
				this.$emit('saveProfileLocationTags', loc);
			}
		},
		getImg(el) {
			try {
				return require(`@/assets/img/locations/${this.titleToName(this.locationTagNameFormat(el))}.jpg`);
			} catch(e) {
				return require(`@/assets/img/default.jpg`);
			}
		},
		saveDropLocations(loc) {
			if(this.selectedDropLocations.includes(loc)) {
				this.selectedDropLocations = this.selectedDropLocations.filter(item => item != loc)
				this.$emit('saveProfileLocationTags', null, loc);
			} else {
				this.selectedDropLocations.push(loc)
				this.$emit('saveProfileLocationTags', loc);
			}
		}
	},
	mounted() {
	}
}
</script>

<style lang="scss">
</style>