<template>
	<Step
		:title="$t('onboarding-likely-purchase-title')"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="i in 10"
				:key="i"
				:column="10"
				:onchange="optionSelectAndGoNext"
				:item="i"
				:selectedValue="selectedValue"
			>
				<strong class="fake-title" >{{ i }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'LikelyPurchase',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
	},
	methods: {
		optionSelectAndGoNext(e) {
			this.$emit('saveProfileInfo', 'likely_purchase', parseInt(e.target.value))
			this.$emit('goNext')
		}
	},
	mounted() {
		if(this.profile.buyer_likely_to_buy && this.step < this.maxStep) {
			this.selectedValue = this.profile.buyer_likely_to_buy;
		}
		
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>