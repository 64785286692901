<template>
	<Step
		:title="stepTitle"
	>
		<div class="custom-row" v-if="profile">
			<div class="col-11 d-flex flex-wrap justify-content-center">
				<StepCard
					v-for="(item, i) in popularCountries"
					:key="`country-${i}`"
					:item="item"
					:onchange="saveSelection"
					:column="7"
					value="short"
					:selectedValue="selectedValue"
				>
					<div class="img-holder">
						<img :src="getImg(item)" alt="">
					</div>
					<strong class="fake-title" >{{ item.name }}</strong>
				</StepCard>
			</div>
		</div>
		<SelectBox
			:options="countries"
			@saveDropSelection="saveDropSelection"
		/>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';
import SelectBox from '@/components/Common/SelectBox.vue';
import countries from '@/static/countries.json'

export default {
	name: 'Country',
	components: {
		Step,
		StepCard,
		SelectBox
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		property: {}
	},
	data() {
		return {
			countries: countries.countries,
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		popularCountries: function() {
			let popularCountries = ['US', 'GB', 'SE', 'DK', 'BE', 'NL', 'ES', 'FR', 'DE', 'IE', 'PL', 'NO', 'AE']
			let countries = this.countries.filter(item => {
				return popularCountries.indexOf(item.short) >= 0
			})

			if(window.innerWidth < 480) {
				countries = countries.splice(0, 2)
			} else if(window.innerWidth < 768) {
				countries = countries.splice(0, 3)
			} else if(window.innerWidth < 1024) {
				countries = countries.splice(0, 5)
			}

			return countries
		},
		stepTitle() {
			if(this.profile.profile_type == 1 || this.profile.profile_type == 5) {
				return this.$t('onboarding-country-buyer-title')
			} else {
				return this.$t('onboarding-country-title')
			}
		}
	},
	methods: {
		saveSelection(e) {
			this.$emit('saveProfileInfo', 'country', e.target.value.toLowerCase());
			this.$emit('goNext')
		},
		saveDropSelection(loc) {
			this.$emit('saveProfileInfo', 'country', loc);
			this.$emit('goNext')
		},
		getImg(item) {
			try {
				return require(`@/assets/img/flags/${item.short.toLowerCase()}.svg`)
			} catch(e) {
				return require(`@/assets/img/default.jpg`);
			}
		},
	},
	mounted() {
		if(this.profile.country) {
			this.selectedValue = this.profile.country
		}
		
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>