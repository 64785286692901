<template>
	<Step
		:title="$t('onboarding-thankyou-title')"
	>
		<div class="text-center">
			<p>{{ $t('onboarding-thankyou-access-text') }}</p>
			<p>{{ $t('onboarding-thankyou-reach-out-text') }}</p>
		</div>
		<div class="text-center mb-3">
			<a href="#" class="btn btn-outline-primary close-iframe" @click.prevent="goToProperties">{{ $t('onboarding-browse-properties') }}</a>
		</div>
		<div class="custom-row" v-if="countryLocation.includes('Spain')">
			<div class="col-3">
				<img :src="getImg('spain', 1)" alt="">
			</div>
			<div class="col-3">
				<img :src="getImg('spain', 2)" alt="">
			</div>
		</div>
		<div class="custom-row" v-if="countryLocation.includes('Dubai')">
			<div class="col-3">
				<img :src="getImg('dubai', 1)" alt="">
			</div>
			<div class="col-3">
				<img :src="getImg('dubai', 2)" alt="">
			</div>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'ThankYou',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.tagtype == 'Location')
			.map(item => item.name ? item.name : item)[0]
		},
	},
	methods: {
		getImg(country, n) {
			try {
				return require(`@/assets/img/onboarding/${country}_${n}.png`);
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
		goToProperties() {
			window.parent.postMessage('redirect', '*');
		}
	}
}
</script>

<style lang="scss">
</style>