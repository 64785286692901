const flashStore = {
	state: {
		flashMessages: []
	},
	getters: {
		flashMessages: state => state.flashMessages
	},
	mutations: {
		showFlashMsg: (store, data) => {
			if(!data.delay) {
				data.delay = 3000
			}
			store.flashMessages.push({message: data.msg, state: data.status});

			if(data.static) {
				return
			}

			setTimeout(() => {
				store.flashMessages.shift();
			}, data.delay);
		},
		clearMsg: (store) => {
			store.flashMessages = []
		}
	},
	actions: {
		showFlash({ commit }, data) {
			commit('showFlashMsg', data)
		},
		showSuccessFlash({ commit }, data) {
			data['status'] = 'success'
			commit('showFlashMsg', data)
		},
		showErrorFlash({ commit }, data) {
			data['status'] = 'error'
			commit('showFlashMsg', data)
		},
		showWarningFlash({ commit }, data) {
			data['status'] = 'warning'
			commit('showFlashMsg', data)
		},
		showInfoFlash({ commit }, data) {
			data['status'] = 'info'
			commit('showFlashMsg', data)
		},
		clearMsg({ commit }) {
			commit('clearMsg')
		}
	}
}

export default flashStore
