<template>
	<Step
		:title="$t('onboarding-planing-title')"
	>
		<div class="custom-row" v-if="profile">
			<div class="col-md-4 d-flex justify-content-center">
				<StepCard
					v-for="(item, i) in options"
					:key="i"
					:column="2"
					value="value"
					:onchange="selectOptionAndGoNext"
					:item="item"
					:selectedValue="selectedValue"
				>
					<strong class="fake-title" >{{ item.label }}</strong>
				</StepCard>
			</div>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'PlanVisit',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			options: [
				{label: 'Yes', value: true},
				{label: 'No', value: false},
			],
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
	},
	methods: {
		selectOptionAndGoNext(e) {
			this.$emit('saveProfileInfo', 'plan_visiting', JSON.parse(e.target.value))
			this.$emit('goNext')
		}
	},
	mounted() {
		if(this.profile.buyer_plan_visiting) {
			this.selectedValue = this.profile.buyer_plan_visiting;
		}
	}
}
</script>

<style lang="scss">
</style>