<template>
	<div :class="[showModal ? 'show' : '', 'modal-box']">
		<div
			:class="[hideBackdrop ? 'transparent' : '', 'modal-backgrop']"
			@click.self="showModal = false"
		></div>
		<div :class="`modal ${size}`">
			<div v-if="!hideHeader" class="modal-header">
				<h5>{{ title }}</h5>
				<CloseBtn v-if="!hideClose" />
			</div>
			<div class="modal-body">
				<slot></slot>
			</div>
			<div v-if="!hideFooter" class="modal-footer">
				<slot name="footer"></slot>
			</div>
		</div>
	</div>
</template>

<script>
import CloseBtn from '@/components/CloseBtn.vue'

export default {
	name: 'Modal',
	components: {
		CloseBtn
	},
	data() {
		return {
			showModal: false
		}
	},
	props: {
		hideHeader: {
			type: Boolean,
			default: false
		},
		hideFooter: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'Modal Title'
		},
		hideClose: {
			type: Boolean,
			default: false
		},
		hideBackdrop: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'md'
		}
	},
	methods: {
		openModal() {
			this.showModal = true;

			this.$nextTick(() => {
				this.$el.querySelector('input').focus();
			});
		},
		hideModal() {
			this.showModal = false;
		}
	}
}
</script>

<style scoped lang="scss">
.modal-box {
	display: none !important;
	&.show {
		display: block !important;
	}
}
.modal-backgrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.5);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	&.transparent {
		background-color: transparent;
	}
}
.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10000;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	width: 100%;
	max-width: 500px;
	border-radius: 25px;
	max-height: calc(100% - 1.5rem);
	overflow-y: auto;
	overflow-x: hidden;
	padding: 10px 40px;

	&.sm {
		max-width: 300px;
	}

	&.lg {
		max-width: 720px;
	}

	.modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
		border-bottom: 1px solid $gray-200;
		padding: 20px 0;

		h5 {
			margin: 0;
			color: $primary;
			text-transform: capitalize;
		}
	}

	.modal-footer {
		font-size: 12px;
		color: $black;
		font-weight: 500;

		a:not([class]) {
			color: $primary;
		}
	}
}
</style>