<template>
	<Step
		:title="`${$t('onboarding-benefits-title')} newbuildproperties.com`"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="(item, i) in options"
				:key="i"
				:column="2"
				value="value"
				:onchange="selectAndGoNext"
				:item="item"
				:selectedValue="selectedValue"
			>
				<strong class="fake-title" >{{ item.label }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'Style',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		goNext: Function
	},
	data() {
		return {
			options: [
				{label: this.$t('onboarding-benefits-look-for-info'), value: 'look'},
				{label: this.$t('onboarding-benefits-new-clients'), value: 'gain_leads'}
			],
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.tagtype == 'Location')
			.map(item => item.name ? item.name : item)[0]
		}
	},
	methods: {
		selectAndGoNext(e) {
			this.$emit('saveProfileInfo', 'benefits', e.target.value)
			this.$emit('goNext');
		}
	},
	mounted() {
		if(this.profile['agent_purpose'] && this.step < this.maxStep) {
			this.selectedValue = this.profile['agent_purpose'];
		}

		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>