<template>
	<div class="custom-tel-input" ref="holder">
		<div class="input-holder">
			<div class="code-holder">
				<span class="code f32" @click.prevent="toggleFlagDrop">
					<vm-flag v-if="selectedCountry" class="flag" :code="selectedCountryShort"/>
					<a href="#" @click.prevent="" class="ml-1">
						<i class="icon-arrow-down"></i>
					</a>
				</span>
				<transition name="fade">
					<div v-show="showFlagDrop" class="js-phone-drop">
						<input type="text" class="bold-input mb-2" ref="dropsearch" v-model="searchCountryValue">
						<ul class="all-countries-list">
							<li
								v-for="country in filteredCountries"
								:key="`ml-${country.name}`"
							>
								<a href="#" @click.prevent="insertCountryCode(country)">
									<vm-flag class="flag" :code="country.short.toLowerCase()"/>
									{{ country.code }} {{ country.name }}
								</a>
							</li>
						</ul>
					</div>
				</transition>
			</div>
			<input
				v-model="newPhoneNumber"
				type="text"
				@input="inputHandle"
				class="bold-input"
				name="phone"
				maxlength="14"
				autocomplete="phone"
				:readonly="readonly"
				ref="phonenumber"
				:data-id="dataId"
			>
		</div>
		<div v-if="newPhoneNumber && errorMsg" class="alert alert-danger mt-4">
			<p class="mb-0">{{ $t('common-not-valid-number') }}</p>
		</div>
		<div v-if="newPhoneNumber && errorMsgFormat" class="alert alert-danger mt-4">
			<p class="mb-0"><span>{{ $t('common-not-valid-please-format') }}</span> <a href="#" @click.prevent="showModal">Click here for info</a></p>
		</div>
		<Modal ref="all-countries-modal" id="all-countries-modal" :title="`${$t('common-all-countries-modal-title')}`" titleKey="common-all-countries-modal-title">
			<input type="text" class="form-control mb-2" v-model="searchCountryValue">
			<ul class="all-countries-list">
				<li v-for="item in filteredCountries" :key="item.name">
					<a href="#" @click.prevent="insertCountryCode(item)">
						<vm-flag class="flag" :code="item.short.toLowerCase()"/> {{item.code}} {{item.name}}
					</a>
				</li>
			</ul>
		</Modal>
	</div>
</template>

<script>
import countries from '@/static/countries.json'
import Flag from "@/components/Common/Flag.vue"
import Modal from '@/components/Modal.vue';

export default {
	name: 'PhoneInput',
	props: ['phoneNumber', 'readonly', 'dataId'],
	data() {
		return {
			selectedCountry: null,
			selectedCountryLength: null,
			selectedCountryShort: null,
			newPhoneNumber: null,
			validNumber: null,
			allCountries: countries.countries,
			inputCountry: null,
			errorMsg: null,
			errorMsgFormat: false,
			searchCountryValue: null,
			showFlagDrop: false
		}
	},
	components: {
		Modal,
		'vm-flag': Flag
	},
	methods: {
		insertCountryCode(item) {
			this.newPhoneNumber = item.code;
			this.setupCountryDetails(item);
			this.$refs['all-countries-modal'].hideModal();
			this.showFlagDrop = false;
			this.$refs.phonenumber.focus()
			this.searchCountryValue = null;
		},
		selectCountry(country) {
			if(this.newPhoneNumber && this.hasCode(this.newPhoneNumber) && this.selectedCountry) {
				this.newPhoneNumber = this.newPhoneNumber.replace(this.selectedCountry, country.code);
			} else if(this.newPhoneNumber && !this.hasCode(this.newPhoneNumber) && !this.selectedCountry) {
				this.newPhoneNumber = `${country.code}${this.newPhoneNumber}`
			} else {
				this.newPhoneNumber = country.code;
			}
			
			this.setupCountryDetails(country);
		},
		inputValidation(init) {
			this.newPhoneNumber = this.newPhoneNumber.toString().replace(/[^0-9 ()+]/g, '').replace(/\+\+/g, '+');

			if(!this.newPhoneNumber.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g)) {
				this.errorMsgFormat = true;
				this.errorMsg = null;
				return;
			}
			
			if(this.newPhoneNumber.match(/0{4,}?$/g)) {
				this.errorMsgFormat = false;
				this.errorMsg = this.$t('common-not-valid-number');
				return;
			}
			
			var detectedCountry = null;

			if(this.hasCode(this.newPhoneNumber)) {
				detectedCountry = this.detectCountry(this.newPhoneNumber);
				this.errorMsgFormat = false;
			} else {
				this.errorMsg = null;
				this.errorMsgFormat = true;
			}

			if(!init && this.selectedCountry && !this.errorMsgFormat) this.validateNumber(init)

			if(!init) this.$emit('oninput')
			
			if(detectedCountry) this.setupCountryDetails(detectedCountry);
		},
		inputHandle() {
			this.newPhoneNumber = this.newPhoneNumber.toString().replace(/[^0-9 ()+]/g, '').replace(/\+\+/g, '+');
			this.$emit('savePhone', this.newPhoneNumber.replace(/^00/g, '+'));
			
			let detectedCountry = null;

			if(this.hasCode(this.newPhoneNumber)) {
				detectedCountry = this.detectCountry(this.newPhoneNumber);
				this.errorMsgFormat = false;
			} else {
				this.errorMsg = null;
				this.errorMsgFormat = true;
			}
			
			if(detectedCountry) this.setupCountryDetails(detectedCountry);
		},
		validateNumber(noSaving) {
			var numberWithoutCode = this.newPhoneNumber.startsWith('+') ? this.newPhoneNumber.replace(this.selectedCountry, '') : this.newPhoneNumber.replace(this.selectedCountry.replace(/^\+/g, '00'), '');

			if(numberWithoutCode.length >= this.selectedCountryLength && this.newPhoneNumber.length >= 7) {
				this.validNumber = true;
				this.errorMsg = null;

				if(!noSaving) this.$emit('submitFunc');
			} else {
				this.validNumber = false;
				this.errorMsgFormat = false;
				this.errorMsg = this.$t('common-not-valid-number');
			}
		},
		saveBookingInfo(contactInfo) {
			this.newPhoneNumber = contactInfo['phone'];
		},
		hasCode(number) {
			if(number.charAt(0) == '+' || number.startsWith('00')) return true
			else return false
		},
		detectCountry(number) {
			return this.allCountries.filter(item => {
				var code = number.startsWith('+') ? item.code : item.code.replace('+', '00')
				return number.startsWith(code)
			})[0]
		},
		setInitValue(init) {
			this.newPhoneNumber = this.phoneNumber;

			this.inputValidation(init)
		},
		setupCountryDetails(suggestedCountry) {
			this.selectedCountry = suggestedCountry.code;
			this.selectedCountryLength = suggestedCountry.length;
			this.selectedCountryShort = suggestedCountry.short.toLowerCase();
		},
		toggleFlagDrop() {
			this.showFlagDrop = !this.showFlagDrop

			if(this.showFlagDrop) {
				setTimeout(() => {
					this.$refs.dropsearch.focus()
				}, 100);
			}
		},
		closeOutsideDrop() {
			document.querySelector('body').addEventListener('click', (e) => {
				if(!this.findParentTag(e.target, this.$el)) {
					this.showFlagDrop = false;
				}
			});
		},
		findParentTag(el, tag) {
			while(el.parentNode) {
				el = el.parentNode;
				if (tag == el) return el;
			}
			return null;
		},
		showModal() {
			this.$refs['all-countries-modal'].openModal();
		}
	},
	mounted() {
		if(this.phoneNumber) this.setInitValue(true);
		
		if(this.userCountry && !this.newPhoneNumber) {
			var suggestedCountry = this.allCountries.filter(item => {
				return item.name == this.userCountry
			})[0];

			if(suggestedCountry) this.setupCountryDetails(suggestedCountry);

			if(!this.newPhoneNumber) {
				this.newPhoneNumber = this.selectedCountry;
			}
		}
		this.closeOutsideDrop();
	},
	watch: {
		phoneNumber() {
			if(this.phoneNumber) this.setInitValue(true);
		}
	},
	computed: {
		userCountry() {
			return this.$store.getters.userCountry
		},
		filteredCountries() {
			if(this.searchCountryValue) {
				this.searchCountryValue = this.searchCountryValue.toString().replace(/[^0-9a-zA-Z\+]/g, '').replace(/\+\+/g, '+');
				return this.allCountries.filter(country => {
					var code = this.searchCountryValue.startsWith('+') ? this.searchCountryValue : this.searchCountryValue.replace(/^00/g, '+').toLowerCase()
					return country.name.toLowerCase().indexOf(code) >= 0 || country.code.indexOf(code) >= 0
				})
			} else {
				return this.allCountries
			}
		}
	}
}
</script>
<style lang="scss">
.custom-tel-input {
	.input-holder {
		display: flex;
		align-items: stretch;
		position: relative;

		.form-control {
			flex: 1 1 auto;
			width: 1%;
			margin: 0;
		}
	}

	.code-holder {
		display: flex;
	}

	.code {
		background-color: $gray-150;
		border: 1px solid #9D9D9D;
		box-shadow: 0 0 2px 0 rgba(0, 0, 0, 1);
		border-radius: 7px;
		height: 47px;
		position: relative;
		border-radius: 7px;
		color: $black;
		font-weight: 700;
		display: block;
		padding: 4px 24px;
		min-width: 118px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 18px;

		i {
			padding: 5px;
			font-size: 14px;
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.js-phone-drop {
		position: absolute;
		top: calc(100% + 10px);
		box-shadow: 0 0 40px rgba(108, 93, 211, 0.16);
		left: 0;
		height: 200px;
		right: 0;
		overflow-y: auto;
		overflow-x: hidden;
		background-color: #fff;
		z-index: 10;
		border-radius: 8px;
		padding: 10px;

		.form-control {
			width: 100%;
			background: $gray-200;
			padding: 4px 15px;
			height: 36px;
			min-height: 36px;
		}

		li {
			display: flex;
			align-items: center;
		}
	}

	.drop-holder {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			padding: 10px;
			cursor: pointer;
			transition: color .3s;
			display: flex;
			align-items: center;

			&:hover {
				color: $primary;
			}

			img {
				margin-right: 10px;
			}
		}
	}

	.alert {
		span {
			user-select: none;
			pointer-events: none;
		}

		a {
			font-weight: 700;
		}
	}
}

.phone-msg {
	padding: 5px;
	background-color: #4fb0ae;
	margin-top: 9px;
	color: #fff;
	display: inline-block;
	line-height: 1;
	border-radius: 5px;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: -6px;
		left: 10px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 5px 6px 5px;
		border-color: transparent transparent #4fb0ae transparent;
	}

	&.hide {
		display: none !important;
	}
}

.all-countries-list {
	padding: 0;
	margin: 0;
	list-style: none;

	li {
		padding: 5px 0;
	}

	a {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.flag {
		margin-right: 10px;
	}
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
