export default {
	methods: {
		locationTagNameFormat(tag, showParent) {
			let locationTag = tag.replace(/-/g, ' > ')
			let lastSign = locationTag.lastIndexOf('>');
			let location = locationTag.substring(lastSign + 1).trim();
			let substr = locationTag.substring(0, lastSign);
			let parentLocation = substr.substring(substr.lastIndexOf('>') + 1);
			
			return parentLocation && showParent ? `${parentLocation.trim()} > ${location}` : location
		},
		titleToName(str) {
			return str.replace(/ /g, '_').toLowerCase();
		},
		dateDiff(d1, d2) {
			return d1.getTime() - d2.getTime()
		},
		formatingPrice(property) {
			let formatedPrice = property.toString();
			return parseInt(formatedPrice.replace(/\s|\.|,|[a-z]|[A-Z]/g, '')) || 0;
		},
	}
}