export let locationCountries = [
	{title: 'Spain', tag: 'Spain'},
	{title: "Dubai", tag: "United Arab Emirates > Dubai"}
]

export let mainLocations = [
	{title: 'Malaga', tag: 'Spain > Andalucia > Malaga', subtitle: 'Costa Del Sol'},
	{title: 'Alicante', tag: 'Spain > Valencia > Alicante', subtitle: 'Costa Blanca'},
	{title: 'Murcia', tag: 'Spain > Murcia > Murcia', subtitle: 'Costa Calida'},
]

export var popularLocations = [
	"Spain > Andalucia > Malaga > Manilva",
	"Spain > Andalucia > Malaga > Casares",
	"Spain > Andalucia > Malaga > Estepona",
	"Spain > Andalucia > Malaga > Benahavis",
	"Spain > Andalucia > Malaga > Marbella",
	"Spain > Andalucia > Malaga > Mijas",
	"Spain > Andalucia > Malaga > Fuengirola",
	"Spain > Andalucia > Malaga > Benalmadena",
	"Spain > Andalucia > Malaga > Torremolinos",
	"Spain > Andalucia > Malaga",
	"Spain > Valencia > Alicante > Altea",
	"Spain > Valencia > Alicante > Aspe",
	"Spain > Valencia > Alicante > Benidorm",
	"Spain > Valencia > Alicante > Denia",
	"Spain > Valencia > Alicante > Finestrat",
	"Spain > Valencia > Alicante > Los Montesinos",
	"Spain > Valencia > Alicante > Torrevieja",
	"Spain > Valencia > Alicante > Orihuela",
	"Spain > Valencia > Alicante > Villajoyosa / La Vila Joiosa",
	"Spain > Valencia > Alicante > Santa Pola",
	"Spain > Valencia > Alicante > Guardamar Del Segura",
	"Spain > Valencia > Alicante > Elche/elx",
	"Spain > Valencia > Alicante > Benissa",
	"United Arab Emirates > Dubai > Mohammed Bin Rashid City",
	"United Arab Emirates > Dubai > Business Bay",
	"United Arab Emirates > Dubai > Jumeirah Village Circle (JVC)",
	"United Arab Emirates > Dubai > Al Furjan",
	"United Arab Emirates > Dubai > Palm Jumeirah",
	"United Arab Emirates > Dubai > Meydan",
	"United Arab Emirates > Dubai > Arjan",
	"United Arab Emirates > Dubai > Ras Al Khor",
	"United Arab Emirates > Dubai > Dubai Sports City",
	"United Arab Emirates > Dubai > Al Jaddaf",
	"Spain > Murcia > Murcia > Los Alcazares",
	"Spain > Murcia > Murcia > San Javier",
	"Spain > Murcia > Murcia > Torre-pacheco",
	"Spain > Murcia > Murcia > Murcia",
	"Spain > Murcia > Murcia > San Pedro Del Pinatar",
	"Spain > Murcia > Murcia > Mar Menor",
	"Spain > Murcia > Murcia > Lorca"
];

export var barProperties = [
	"languages",
	"buyer_pref_close",
	"dev_project_close",
	"seller_project_close"
];

var countries_lang = {
	"dk": "Dansk",
	"nl": "Nederlands",
	"gb": "English",
	"fi": "Suomalainen",
	"fr": "Français",
	"no": "Norsk",
	"es": "Español",
	"se": "Svenska",
	"ch": "Switzerland",
	"pl": "Polskie",
	"be": "Belgium",
	"de": "Deutsch",
	"it": "Italiano",
	"ar": "اَلْعَرَبِيَّةُ",
	"ru": "Русский",
	"ua": "Українська",
	"hu": "Magyar",
	"zh": "中国人",
	"hi": "हिंदी",
	"pt": "Português",
	"ja": "日本語",
	"tr": "Türkçe",
	"af": "Afrikaans",
	"am": "አማርኛ",
	"bg": "Български",
	"ca": "Català",
	"hr": "Hrvatski",
	"cs": "čeština",
	"et": "Eesti keel",
	"el": "Ελληνικά",
	"he": "עִברִית",
	"is": "íslenskur",
	"id": "Bahasa Indonesia",
	"ko": "한국인",
	"lv": "Latviski",
	"lt": "Lietuvių",
	"ms": "Melayu",
	"ro": "Română",
	"sr": "Српски",
	"sk": "Slovenský",
	"sl": "Slovenščina",
	"sw": "Kiswahili",
	"th": "แบบไทย",
	"vi": "Tiếng Việt",
	"zu": "Zulu",
	"Other": "other"
}

export var languages = [];
modifyObject(countries_lang, languages);

function modifyObject(obj, list) {
	for(var i in obj) {
		list.push({
			name: obj[i],
			code: i
		})
	}
}