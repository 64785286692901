export const domain = {
	methods: {
		domainCheck() {
			if(window.location.hostname == 'my.developments.es' ) return `https://developments.es/`
			else if(window.location.hostname == 'my.newbuildproperties.com' ) return `https://newbuildproperties.com/`
			else if(window.location.hostname == 'my1.developments.es' ) return `https://1.developments.es/`
			else return `https://1.newbuildproperties.com/`
		},
		adminDomainCheck() {
			if(window.location.hostname == 'my.developments.es' || window.location.hostname == 'my.newbuildproperties.com' ) return `https://offplan.es/`
			else return `https://xadmin.developments.es/`
		},
		domainNBPCheck() {
			if (window.location.hostname == 'my.newbuildproperties.com' || window.location.hostname == 'my.developments.es') return `https://newbuildproperties.com/`
			else return `https://1.newbuildproperties.com/`
		},
		mainDomainShort() {
			if (window.location.hostname.indexOf('developments.es') >= 0) return 'deves';
			else return 'nbp';
		},
		stageURL() {
			if(window.location.hostname == 'localhost') return `https://1.newbuildproperties.com`
			else return window.location.origin
		}
	}
 }
