<template>
	<Step
		:title="$t('onboarding-type-title')"
	>
		<div class="custom-row" v-if="profile">
			<StepCard
				v-for="(item, i) in typeOptions"
				:key="i"
				:column="3"
				value="name"
				:onchange="selectAndGoNext"
				:item="item"
				:selectedValue="selectedValue"
			>
				<div  class="img-holder">
					<img :src="getImg(item)" alt="">
				</div>
				<strong class="fake-title" >{{ item.label }}</strong>
			</StepCard>
			<StepCard
				:column="3"
				value="name"
				:onchange="selectAndGoNext"
				:item="dkOption"
				:selectedValue="selectedValue"
			>
				<div  class="img-holder">
					<img :src="getImg(dkOption)" alt="">
				</div>
				<strong class="fake-title" >{{ dkOption.label }}</strong>
			</StepCard>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';

export default {
	name: 'Type',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		goNext: Function,
		property: {}
	},
	data() {
		return {
			typeOptions: [
				{name: 'house', label: this.$t('onboarding-type-house')},
				{name: 'apartment', label: this.$t('onboarding-type-apartment')},
			],
			dkOption: {
				label: this.$t('onboarding-style-dk'),
				name: 'not_answered'
			},
			selectedValue: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		},
		countryLocation() {
			return this.profile.tags
			.filter(item => item.tagtype == 'Location')
			.map(item => item.name ? item.name : item)[0]
		},
	},
	methods: {
		getImg(el) {
			let areaLocation = this.countryLocation.substring(this.countryLocation.lastIndexOf('>') + 1).trim().toLowerCase()
			
			try {
				return require(`@/assets/img/onboarding/${areaLocation}_${el.name}.jpeg`)
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
		selectAndGoNext(e) {
			this.$emit('saveProfileInfo', 'type', e.target.value)
			this.$emit('goNext');
		}
	},
	mounted() {
		if(this.profile[this.property] && this.step < this.maxStep) {
			this.selectedValue = this.profile[this.property]
		}
	},
}
</script>

<style lang="scss">
</style>