<template>
	<div class="select-location-block">
		<div class="select-input-holder">
			<input
				type="text"
				class="bold-input"
				@keyup.down="navigateDropDown"
				@input="filterOptions"
				v-model="search"
				@keyup.enter="singleSelect"
				@click="fetchLocations"
				@blur="closeOutsideDrop($event)"
				@keydown.esc="closeOutsideDrop($event)"
			>
			<a href="#" class="drop-opener" @click.prevent="toggleDrop"><i class="icon-arrow-down"></i></a>
			<div :class="[showedDrop ? 'show' : '', fixedDrop ? 'fixed-drop' : '', 'result-drop']">
				<ul class="result-list" @click.stop="">
					<li v-if="loading" class="py-2 px-4">
						<i>Loading...</i>
					</li>
					<li
						v-for="(location, i) in locations"
						:key="`${location}-${i}`"
						@keyup.down="navigateDropDown"
						@keyup.up="navigateDropUp"
					>
						<a
							href="#"
							@click.prevent="selectLocation(location)"
							:data-id="`${dataId}-${locationFormater(location.name || location)}`"
						>
							{{locationFormater(location.name || location)}}
						</a>
					</li>
					<li v-if="locations && locations.length == 0" class="p-3">{{ $t('common-no-results') }}</li>
					<li v-else-if="!loading" class="py-2 px-4">...</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SelectLocationBox',
	props: {
		value: {},
		multiple: {
			type: Boolean,
			default: false
		},
		customFormat: {
			type: Boolean,
			default: false
		},
		fixedDrop: {
			type: Boolean,
			default: false
		},
		dataId: {},
		selectedValue: {},
		mainLocation: {},
	},
	data() {
		return {
			showedDrop: false,
			search: null,
			resultOptions: this.options,
			multipleSelect: this.value,
			locations: null,
			loading: false
		}
	},
	methods: {
		locationFormater(location) {
			if(this.customFormat) {
				return this.locationTagFormat(location);
			} else {
				return this.locationTagNameFormat(location)
			}
		},
		locationTagFormat(locationTag) {
			var lastSign = locationTag.lastIndexOf('>') + 1;
			return locationTag.substring(lastSign);
		},
		locationTagNameFormat(locationTag) {
			var lastSign = locationTag.lastIndexOf('>');
			var location = locationTag.substring(lastSign + 1).trim();
			var substr = locationTag.substring(0, lastSign).trim();
			var parentLocation = substr.substring(substr.lastIndexOf('>') + 1).trim();

			return parentLocation ? `${parentLocation} > ${location}` : location
		},
		singleSelect() {
			if(this.locations && this.locations.length == 1) {
				this.selectOption(this.locations[0])
			}
		},
		parseValue(str) {
			str = str.toLowerCase();
			str = str.replace(/í/g, 'i').replace(/é/g, 'e').replace(/á|à/g, 'a').replace(/ú/g, 'u').replace(/ó/g, 'o');
			return str
		},
		selectLocation(location) {
			this.$emit('saveDropLocations', location.name || location)
			this.showedDrop = false;
			this.search = null;
			this.locations = null;
		},
		deselectOption(location) {
			this.$emit('saveDropLocations', location);
		},
		removeSelected(locations) {
			return locations.filter(loc => {
				let selected = this.selectedValue.find(selected => selected == loc.name)
				return loc.name != this.mainLocation && !selected
			})
		},
		fetchLocations() {
			var search = this.search && this.search.length > 0 ? this.search.toLowerCase() : '';

			this.fetchLocationTags(search)
			.then(res => {
				this.locations = this.removeSelected(res.data.tags);
				this.loading = false;
			})

			this.showedDrop = true;
		},
		filterOptions() {
			this.loading = true;
			clearTimeout(this.inputTimeout);

			this.inputTimeout = setTimeout(() => {
				this.fetchLocations()
				this.showedDrop = true;
			}, 500)
		},
		navigateDropDown: function(e) {
			var holder = e.target.closest('.select-location-block');
			//don't remove, fix for dropdown arrow navigation
			var state = false;
			var linksList = holder.querySelectorAll('.result-drop > .result-list > li > a');

			//remove focus from previous item
			e.target.blur();

			linksList.forEach((item, i) => {
				// check if result-list item has HOVER class and it's not the last item - to switch hover class to a next item
				if(!state && item.classList.contains('hover') && (i+1 != linksList.length)) {
					this.highlightOption(i+1, i, linksList);
					state = true;

					//scroll to hovered item
					holder.querySelector('.result-drop').scrollTop = holder.querySelector('.result-drop a.hover').offsetTop;
					
				//if result-list item is the last in the list - go to first item
				} else if(!state && item.classList.contains('hover') && (i+1 == linksList.length)) {
					this.highlightOption(0, i, linksList);
					state = true;
				}
			});

			//if no hovered items - set a first one
			if(!holder.querySelector('.result-drop a.hover')) {
				holder.querySelector('.result-drop a').classList.add('hover')
				holder.querySelector('.result-drop a').focus()
			}
		},
		highlightOption: function(n, i, links) {
			links[n].classList.add('hover');
			links[n].focus();
			links[i].classList.remove('hover');
		},
		navigateDropUp: function(e) {
			var holder = e.target.closest('.select-location-block');
			var state = false;
			var linksList = holder.querySelectorAll('.result-drop > .result-list > li > a');

			e.target.blur()

			linksList.forEach((item, i) => {
				// check if result-list item has HOVER class and it's not the first item - to switch hover class to a next item
				if(!state && item.classList.contains('hover') && i != 0) {
					this.highlightOption(i-1, i, linksList);
					state = true;

				//if result-list item is the first in the list - go to last item
				} else if(!state && item.classList.contains('hover') && i == 0) {
					this.highlightOption(linksList.length-1, i, linksList);
					state = true;
				}
			})
		},
		closeOutsideDrop(e) {
			setTimeout(() => {
				this.showedDrop = false;
			}, 200);
		},
		fetchLocationTags(query) {
			this.loading = true;
			var params = {
				types: [7],
				paginate_by: 10,
				with_legacy: false,
				startswith: this.mainLocation
			}

			if(query) {
				params['q'] = query
			}

			return this.$api.post('/tag/search', params)
		},
		toggleDrop() {
			this.$el.querySelector('.search-input').focus();
			this.fetchLocations();
			this.showedDrop = !this.showedDrop;
		}
	}
}
</script>
<style scoped lang="scss">
.select-location-block {
	position: relative;
	max-width: 600px;
	margin: 0 auto 60px;

	.search-input {
		margin-bottom: 5px;
		width: 100%;
		padding: 10px 35px 10px 20px;

		&.is-invalid + .drop-opener {
			display: none;
		}
	}

	.drop-opener {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		padding: 7px;
		pointer-events: none;
	}
}

.result-drop {
	opacity: 0;
	height: 0;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: #fff;
	color: #000;
	max-height: 230px;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: left;
	z-index: 2;
	box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.3);
	cursor: pointer;

	@include media-up(s2k) {
		font-size: 20px;
	}
	
	&.show {
		opacity: 1;
		z-index: 5;
		height: auto;
	}

	.result-list {
		padding: 0;
		margin: 0;
		list-style-type: none;
		text-transform: capitalize;
	}
	
	a {
		display: block;
		outline: none;
		display: block;
		padding: 10px 24px;
		color: inherit;
		transition: background-color .3s;

		&:hover,
		&.hover {
			text-decoration: none;
			background-color: $gray-300;
		}
	}
}
</style>
