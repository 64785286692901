<template>
	<Step
		:title="$t('onboarding-name-title')"
		showNext
		@goNext="save"
	>
		<div class="custom-row" v-if="profile">
			<div class="col-md-6">
				<label class="label">
					<input
						type="text"
						v-model="fullName"
						class="bold-input"
						@keyup.enter="save(),goNext()"
					>
				</label>
			</div>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';

export default {
	name: 'Name',
	components: {
		Step
	},
	props: {
		title: String,
		saveProfileLocationTags: Function
	},
	data() {
		return {
			fullName: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		}
	},
	methods: {
		save(e) {
			let name = this.fullName.split(' ');
			this.$emit('saveProfileInfo', 'first_name', name[0])

			if(name.length > 1) {
				this.$emit('saveProfileInfo', 'last_name', name[1])
			}
		},
	},
	mounted() {
		if(this.profile.first_name) {
			this.fullName = this.profile.first_name;
		}
		if(this.profile.last_name) {
			this.fullName += ` ${this.profile.last_name}`;
		}
	}
}
</script>

<style lang="scss">
</style>