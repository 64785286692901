export default {
	data() {
		return {
			loadingCounter: 0,
			messages: {}
		}
	},
	methods: {
		fetchTranslations() {
			this.loadingCounter++;
			let prefixes = ['common-', 'header-', 'agents-', 'contact-', 'index-', 'property-', 'footer-', 'countdown-', 'onboarding-', 'resetpassword-', 'jobs-', 'developers-'];
			this.messages = {};

			this.$api0.get(`/2/labels.json?lang=${this.$i18n.locale}&prefixes=${prefixes.join(',')}`)
			.then((res) => {
				this.messages = res.data;
				this.$store.commit('setMessages', this.messages);
				this.$i18n.setLocaleMessage(this.$i18n.locale, this.messages);
				
				setTimeout(() => {
					this.messagesLoading = false;
				}, 100);

				localStorage.setItem('labelsLastUpdated', new Date())
				localStorage.setItem('labels', JSON.stringify(this.messages))
				localStorage.setItem('locale', this.$i18n.locale)
			})
			.catch(error => {
				console.log(error)
				if(this.loadingCounter < 3) {
					this.fetchTranslations()
				}
			});
		},
		setupTranslations() {
			let labelsLastUpdated = localStorage.getItem('labelsLastUpdated')
			let lastVisited = localStorage.getItem('lastVisited')
			let countedTimeDiff = this.dateDiff(new Date(), new Date(labelsLastUpdated))
			let dateCheck = labelsLastUpdated && (Math.floor(countedTimeDiff / 1000 / 60) >= 10 || new Date(labelsLastUpdated).getDate() !== new Date().getDate())
			let visitedCheck = lastVisited && (Math.floor(this.dateDiff(new Date(), new Date(lastVisited)) / 3600000) >= 24)
			
			if(dateCheck || !localStorage.getItem('labels') || this.$i18n.locale != localStorage.getItem('labelsLocale') || this.isEmptyObj(this.messages) || visitedCheck) {
				this.fetchTranslations()
			} else if(localStorage.getItem('labels') && this.$i18n.locale == localStorage.getItem('locale')) {
				try {
					this.$set(this, 'messages', JSON.parse(localStorage.getItem('labels')))
				} catch(e) {
					console.log(e)
				}
				
				this.$i18n.setLocaleMessage(this.$i18n.locale, this.messages);

				setTimeout(() => {
					this.messagesLoading = false;
				}, 100);
			}
		},
	}
}