import './assets/scss/app.scss'
import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import mainStore from './store/index.js'
import flashStore from './store/flash.js'

import { domain } from './mixins/domain'
import formating from './mixins/formating'
import client from './mixins/client'

import labels from './plugins/labels'

const i18n = createI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages: {}
})

const app = createApp(App)

const store = createStore({
	modules: {
		mainStore: mainStore(app),
		flashStore: flashStore
	}
})

app.config.errorHandler = function(err, vm, info) {
	console.error(err)
	const baseURL = process.env.VUE_APP_API_URL || 'https://api1.newbuildproperties.com'
	const data = {
		level: 'error',
		msg: JSON.stringify({
			err: err.toString() || err,
			info: info,
			version: process.env.VUE_APP_VERSION,
			baseURL: process.env.BASE_URL,
			location: window.location.href
		})
	};

	axios.post(`${baseURL}/event/log_fe_bug`, data)
	.then(res => {
		console.log('log_fe_bug')
	})
}

app.use(VueAxios, axios)

app.config.globalProperties.$axios = axios.create({
	timeout: 7500
});
app.config.globalProperties.$api0 = axios.create({
	baseURL: process.env.VUE_APP_API_URL || 'https://api1.newbuildproperties.com',
	timeout: 7500
});
app.config.globalProperties.$api = axios.create({
	baseURL: process.env.VUE_APP_API_URL || 'https://api1.newbuildproperties.com',
	timeout: 7500
});

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token') || localStorage.getItem('token');

if(token) {
	app.config.globalProperties.$api.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

axios.interceptors.response.use(responseHandler, errorHandler);

function responseHandler(response) {
	return response;
}
function errorHandler(error) {
	console.error(error)
	app.config.errorHandler.call(error, null, this);
	return Promise.reject(error);
}
app.mixin(formating);
app.mixin(client);
app.mixin(labels);

app.provide('$axios', app.config.globalProperties.$axios)
app.provide('$api', app.config.globalProperties.$api)
app.provide('$api0', app.config.globalProperties.$api0)

app.config.globalProperties.$domain = domain;
app.use(i18n)
app.use(store)
app.use(router)
app.mount('#app')
