import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import Onboarding from '../views/Onboarding.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/onboarding/:type?',
    name: 'onboarding',
    component: Onboarding
  }
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.beforeEach((to, from, next) => {
  console.log(to)
	if((to.path === '/login' || to.path === '/signup') && localStorage.getItem('token')) {
	  next('/')
	} else if(to.query.token && to.query.user_id) {
    localStorage.setItem('token', to.query.token)
    localStorage.setItem('user_id', to.query.user_id)
	  next()
	} else if(!localStorage.getItem('token') && (to.path !== '/login' && to.path !== '/signup')) {
	  next('/login')
	} else {
    next()
  }
})

export default router
