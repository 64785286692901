<template>
	<Step
		:title="$t('onboarding-main-location-title')"
	>
		<div class="custom-row" v-if="profile">
			<div class="col-md-8 d-flex flex-wrap">
				<StepCard
					v-for="(loc, i) in locations"
					:key="`location-${i}`"
					:item="loc"
					:onchange="selectLocationAndGoNext"
					:column="2"
					value="tag"
				>
					<div  class="img-holder">
						<img :src="getImg(loc)" alt="">
					</div>
					<strong class="fake-title" >{{ loc.title }}</strong>
				</StepCard>
			</div>
		</div>
	</Step>
</template>

<script>
import Step from '@/components/Onboarding/Step.vue';
import StepCard from '@/components/Onboarding/StepCard.vue';
import { locationCountries } from '@/static/staticData.js'

export default {
	name: 'CountryLocation',
	components: {
		Step,
		StepCard
	},
	props: {
		title: String,
		saveProfileLocationTags: Function,
		goNext: Function
	},
	data() {
		return {
			locations: locationCountries,
			location: null
		}
	},
	computed: {
		profile() {
			return this.$store.getters.profile;
		}
	},
	methods: {
		selectLocation(e) {
			this.$emit('saveProfileLocationTags', e.target.value);
		},
		selectLocationAndGoNext(e) {
			this.$emit('saveProfileLocationTags', e.target.value);
			this.$emit('goNext');
		},
		getImg(el) {
			try {
				return require(`@/assets/img/locations/${el.title.toLowerCase()}.jpg`);
			} catch(e) {
				return require('@/assets/img/default.jpg');
			}
		},
	},
	mounted() {
		this.$emit('mounted', this.$el)
	}
}
</script>

<style lang="scss">
</style>